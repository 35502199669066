import React from "react";
import { Link } from "react-router-dom";

// single service
export function ServiceItem({ icon, title, desc }) {
  return (
    <div className="col-xl-3 col-md-6">
      <div className="tp-service text-center white-bg pt-60 pb-45 pl-25 pr-25 mb-30">
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          {title}
        </h3>
        <p>{desc}</p>
      </div>
    </div>
  );
}

const Services = () => {
  const servicesData = [
    {
      icon: "pe-7s-print", // Example icon class, replace with actual icon classes
      title: "Impresión Digital",
      desc: "Perfecta para tiradas cortas con acabados de alta calidad en variedad de materiales."
    },
    {
      icon: "pe-7s-notebook",
      title: "Impresión Offset",
      desc: "Ideal para grandes volúmenes manteniendo la consistencia y calidad, desde libros hasta folletos."
    },
    {
      icon: "pe-7s-expand1",
      title: "Gran Formato",
      desc: "Publicidad de impacto con lonas y vinilos para captar la atención desde la distancia."
    },
    {
      icon: "pe-7s-id",
      title: "Papelería Corporativa",
      desc: "Tarjetas, papelería y todo lo que tu empresa necesita."
    }
    // ... Add more services as needed
  ];

  return (
    <>
      {/* <!-- service area start  --> */}
      <div className="tp-service-ara grey-bg-4 pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-25"  style={{ color: '#0EFDF3', fontSize: '25px'}}>NUESTROS SERVICIOS</span>
                <h2 className="tp-section-title">Explora Nuestros Servicios</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((service, index) => (
              <ServiceItem
                key={index}
                icon={service.icon}
                title={service.title}
                desc={service.desc}
              />
            ))}
            <div className="text-center mt-60" >
              <Link to="/service" className="tp-btn-border">
                Todos los Servicios
                <span>
                  {/* SVG icons remain unchanged */}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service area end  -->   */}
    </>
  );
};

export default Services;
