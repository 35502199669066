import React from 'react';

const WhatsAppButton = () => {
    const phoneNumber = '7721122615'; // Cambia esto por tu número de teléfono real
    const message = encodeURIComponent("Hola! Me gustaría saber más información."); // Mensaje predeterminado

    return (
        <a href={`https://wa.me/${phoneNumber}?text=${message}`}
           target="_blank"
           rel="noopener noreferrer"
           style={{
               position: 'fixed',
               right: '20px',
               bottom: '20px',
               backgroundColor: '#25D366',
               color: 'white',
               borderRadius: '50%',
               width: '60px',
               height: '60px',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               fontSize: '30px',
               boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
               textDecoration: 'none'
           }}>
            <i className="fab fa-whatsapp"></i>
        </a>
    );
};

export default WhatsAppButton;
