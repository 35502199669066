import AppNavigation from "./app-navigation/AppNavigation";
import WhatsAppButton from './components/WhatsAppButton'; // Asegúrate de usar la ruta correcta


function App() {
  return <AppNavigation />;
  <div>
            <h1>Hola, bienvenido a mi aplicación</h1>
            <WhatsAppButton />
        </div>
  
}

export default App;
