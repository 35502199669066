import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import Gallery from "./Gallery";
import Feature from "./Feature";
import Testimonial from "../common/Testimonial/Testimonial";
import FullScreenBtn from "../common/FullScreenBtn";
import TopAbout from "./TopAbout";
import TopCtaArea from "./TopCtaArea";
import HomeSlider from "./HomeSlider";
import WhatsAppButton from '../WhatsAppButton'; // Asegúrate de usar la ruta correcta
import Carrusel from "../common/Testimonial/ClientCarousel";



const Home = () => {
  return (
    <>
      <Menu />
      <HomeSlider />
      <TopAbout />
      <TopCtaArea />
      <Gallery />
      <Feature />
      <FullScreenBtn />
      <Footer />
      <WhatsAppButton/>
    </>
  );
};

export default Home;
