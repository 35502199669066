import React from "react";
import { Link } from "react-router-dom";

const TopAbout = () => {
  return (
    <>
      {/* <!-- about area start --> */}
      <div className="tp-about-area pt-140">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="tp-about-left pr-90">
                <div className="tp-section-wrapper pt-5">
                <span className="tp-section-subtitle" style={{ color: '#0EFDF3', fontSize: '22px' }}>
                  Impresión y Publicidad
                </span>

                  <h1 className="tp-section-title pt-25 mb-30">
                  ESENCIA CREATIVA
                  </h1>
                  <p>
                    Descubre la diferencia que la calidad superior y la atención personalizada pueden hacer en tus proyectos de impresión. Con tecnología avanzada y una meticulosa atención al detalle, transformamos tus ideas en productos impresos que destacan.
                  </p>
                  <p className="pt-10">
                    Ya sea para negocios emergentes o corporativos establecidos en Querétaro, nuestros servicios de impresión personalizados están diseñados para impulsar tu marca al éxito. Desde materiales promocionales hasta soluciones de gran formato, ESENCIA CREATIVA es tu aliado en cada paso.
                  </p>
                </div>
                <div className="mt-45 mb-30">
                  <Link to="/about">
                    <div className="tp-theme-btn d-flex align-items-center">
                      <div className="tp-btn-inner"  >
                        <p className="mb-0"  style={{ color: '#0EFDF3' }}>Explora <br/>nuestros Servicios</p>
                        <b>Conócenos</b>
                        <span className="d-inline-block">
                          {/* SVG icons remain unchanged */}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-right p-relative">
                <div className="about-img text-md-end">
                  <img src="assets/img/about/about.png" alt="Calidad de Impresión" />
                </div>
                <div 
                  className="tp-about-info text-center grey-bg-4"
                  style={{ 
                    backgroundColor: '#FE32A5', // fondo rosa
                    padding: '50px', // hace más grande el div aumentando su padding
                     // texto en color blanco
                  }}
                >
                  <h4 style={{ 
                    fontSize: '26px', color: '#FFFFFF' // tamaño de fuente más grande para el h4
                  }}>
                    Años de <br />
                    Innovación y Servicio
                  </h4>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- about about end--> */}
    </>
  );
};

export default TopAbout;
