import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Nuestros Servicios</h3>
                        <ul>
                          <li><a href="/service">Impresión Digital</a></li>
                          <li><a href="/service">Impresión Offset</a></li>
                          <li><a href="/service">Gran Formato</a></li>
                          <li><a href="/service">Libros y Revistas</a></li>
                          <li><a href="/service">Papelería Corporativa</a></li>
                          {/* Más servicios según el brief */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Acerca de Nosotros</h3>
                        <ul>
                          <li><a href="/about">Inicio</a></li>
                          <li><a href="/about">Quines Somos</a></li>
                          <li><a href="/service">Servicios</a></li>
                          <li><a href="/contact">Contacto</a></li>
                          {/* Enlaces adicionales según sea necesario */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Contacto</h3>
                        <ul>
                          <li><a href="tel:+1234567890">(+52) </a></li>
                          <li><a href="mailto:contacto@deseartegc.com">contacto@.com</a></li>
                          {/* Incluir otros detalles de contacto */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Síguenos</h3>
                        {/* Enlaces a redes sociales */}
                        <ul className="tp-footer-social-icon">
                          <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                          <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                          {/* Más redes sociales */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} ESENCIA CREATIVA. Todos los derechos reservados.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                {/* Redes sociales, si es necesario */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
