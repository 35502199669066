import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li >
        <Link to="/">Inicio</Link>
        
      </li>
      <li>
        <Link to="/about">Acerca de</Link>
      </li>
      <li className="">
        <Link to="/service">Servicios</Link>
      </li>
      <li>
        <Link to="/contact">Contacto</Link>
        
      </li>
    </ul>
  );
};

export default Menus;
