import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoPopup from "../../common/Modals/VideoPopup";

const Contact = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <>
      <div className="tp-sv-contact grey-bg-4 pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center pb-40">
                <span className="tp-section-subtitle mb-25"  style={{ color: '#0EFDF3', fontSize: '25px' }}>
                  Contáctanos
                </span>
                <h2 className="tp-section-title">
                  ¿No encuentras lo que buscas? <br />
                  ¡Contáctanos!
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-lg-8">
            <div className="" style={{ width: '100%', height: '530px' }}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d954555.4816006742!2d-99.81980775!3d20.8425118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d344d16144705f%3A0x9146b1c4a44869bb!2sQuer%C3%A9taro!5e0!3m2!1ses!2smx!4v1711604642116!5m2!1ses!2smx"
            style={{ border: '0', width: '100%', height: '100%' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
            </div>
            <div className="col-lg-4">
              <div className="tp-ct-info-box black-bg">
                <div className="tp-ct-info tp-ct-info-border pt-50 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{" "}
                    Contacto Online
                  </h3>
                  <p className="pser">
                    Calle de la Imprenta, 123 <br />
                    Ciudad Creativa, CP 45678 <br />
                    (+34) 123 456 789
                  </p>
                </div>
                <div className="tp-ct-info pt-60 pl-50 pb-35">
                  <h3 className="tp-ct-info__title text-white mb-35">
                    <span>
                      <i className="fal fa-address-book"></i>
                    </span>{"Horario de Atención"}
                    
                  </h3>
                  <p className="pser">
                    Lunes a Viernes:  <br /> 09:00 am - 07:00 pm <br />
                    Sabado: <br /> 9:00 am - 02:00 pm
                  </p>
                </div>
                {/* Agregar enlaces a redes sociales si es necesario */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"} // Cambia según corresponda
      />
    </>
  );
};

export default Contact;
