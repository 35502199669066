import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import Services from "../../HomeTwo/Services";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import AboutFeature from "./AboutFeature";
import AboutService from "./AboutService";
import WhatsAppButton from '..//../WhatsAppButton'; // Asegúrate de usar la ruta correcta
import FullScreenBtn from "..//../common/FullScreenBtn";



const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutService />
      <AboutCompany />
      <AboutFeature />
      <Services />
      <FullScreenBtn/>
      <Footer />
      <WhatsAppButton/>
    </>
  );
};

export default About;
