import React, { useState } from "react";
import { Link } from "react-router-dom";

const AboutService = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <>
      <div className="tp-about-section black-bg p-relative">
        <div
          className="tp-ab-bg d-none d-lg-block"
          style={{ backgroundImage: `url("assets/img/service/service-bg.jpg")` }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-6">
              <div className="tp-ab-text pt-130 pl-150 p-relative">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-30">
                  ¿Quiénes somos?
                  </span>
                  <h2 className="tp-section-title text-white">
                  Nosotros                  </h2>
                  <p className="pser mb-30">
                  Somos profesionales en medios impresos, nos especializamos además en el desarrollo de Estrategias de Marketing y Publicidad buscando brindar en nuestros clientes una estructura total alrededor de su producto, servicio o campaña, con mayor rentabilidad por medio del conocimiento del mercado al que se dirigen dichos esfuerzos.                   </p>

                  <div className="mt-50 mb-130">
                    <Link to="/contact" className="tp-btn-white">
                    CONTÁCTANOS
                      <span>
                        {/* SVG icons remain unchanged */}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default AboutService;
