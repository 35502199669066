import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";

const OurService = () => {
  const servicesData = [
    // La lista completa de servicios
    {
      icon: "pe-7s-expand1",
      title: "Alta Calidad",
      desc: "Publicidad en gran tamaño, lonas, vinilos, papeles especiales."
    },
	{
      icon: "pe-7s-id",
      title: "Papelería Corporativa",
      desc: "Tarjetas, papel de alta calidad."
    },
	{
      icon: "pe-7s-tools",
      title: "Grabado/Corte Láser",
      desc: "Madera, acrílico, papel."
    },
	{
      icon: "pe-7s-map",
      title: "Personalización de Productos",
      desc: "Variedad de materiales."
    },
    {
      icon: "pe-7s-safe",
      title: "Rotulación Cehicular",
      desc: "Etiquetas."
    },
    {
      icon: "pe-7s-map-2",
      title: "Señalética corporativa",
      desc: "Guías visuales corporativas."
    },
    {
      icon: "pe-7s-paint",
      title: "Serigrafía",
      desc: "Textiles, carteles, promocionales."
    },
    {
      icon: "pe-7s-coffee",
      title: "Sublimación",
      desc: "Textiles, tazas."
    },
    {
      icon: "pe-7s-gift",
      title: "Utilitarios",
      desc: "Productos personalizables."
    },
    {
      icon: "pe-7s-paint-bucket",
      title: "Branding",
      desc: "Identidad corporativa."
    },
    {
      icon: "pe-7s-note2",
      title: "Logística de Eventos",
      desc: "Suministro de materiales promocionales."
    },
    {
      icon: "pe-7s-print",
      title: "Impresión Digital",
      desc: "Para tiradas cortas, papel, cartulina y plástico."
    },
    {
      icon: "pe-7s-notebook",
      title: "Impresión Offset",
      desc: "Volumen alto, libros, revistas, folletos."
    },
  ];

  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30" style={{ color: '#0EFDF3', fontSize: '25px' }}>NUESTROS SERVICIOS</span>
                <h2 className="tp-section-title">Explora nuestros servicios</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {servicesData.map((service, index) => (
              <ServiceItem
                key={index}
                icon={service.icon}
                title={service.title}
                desc={service.desc}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
